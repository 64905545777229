import { apiAxiosV1, apiAxiosV2 } from "@/configs/axios";
import { Offer, OfferCount, OfferDetails, OfferV6 } from "@/model/offer";
import { AndroidService } from "./Android";
import {
  API_END_POINT,
  API_END_POINT_V2_1,
  API_END_POINT_V2_2,
} from "@/configs/config";

export const OfferService = {
  async getActiveOffers({ page = 1, tags, sort }) {
    const params = {
      page,
      sort,
    };
    if (tags?.length) {
      params.tags = tags.join(",");
    }
    const url = API_END_POINT + `/v6/offer/user/list?list_type=active`;
    const res = await apiAxiosV2.get(url, {
      params,
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },

  async getCompletedOffers({ page = 1 }) {
    const params = {
      page,
    };
    const url = API_END_POINT + `/v6/offer/user/list?list_type=completed`;
    const res = await apiAxiosV2.get(url, {
      params,
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },
  async getExpiredOffers({ page = 1 }) {
    const params = {
      page,
    };
    const url = API_END_POINT + `/v6/offer/user/list?list_type=expired`;
    const res = await apiAxiosV2.get(url, {
      params,
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },

  async getOngoingOffers({ page = 1 }) {
    const params = {
      page,
    };
    const url = API_END_POINT + `/v6/offer/user/list?list_type=ongoing`;
    const res = await apiAxiosV2.get(url, {
      params,
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },
  async getActiveGameOffers({ page = 1, sort }) {
    const params = {
      page,
      sort,
    };
    params.tags = "GAMING";
    const url = API_END_POINT + `/v6/offer/user/list?list_type=active`;
    const res = await apiAxiosV2.get(url, {
      params,
    });
    return OfferV6.fromAll(res?.data?.results || []);
  },

  async getOfferDetails(offerId) {
    const url = API_END_POINT + `/v6/offer/user/details?offer_id=${offerId}`;
    const res = await apiAxiosV2.get(url);
    const data = res?.data;
    return OfferDetails.from(data);
  },

  async getOfferCtaUrl({ rewardId, ga_id }) {
    const url = `/offer/reward/cta?reward_id=${rewardId}&ga_id=${ga_id}`;
    const res = await apiAxiosV1.get(url);
    return res?.data || null;
  },
  async redeemRetention(data) {
    const url = `/user_offer/retention/redeem`;
    const res = await apiAxiosV1.post(url, data);
    return;
  },

  // count apis
  async getOngoingOffersCount() {
    const url = "user_offer/offerwall/ongoing/count";
    const res = await apiAxiosV1.get(url);
    return OfferCount.from(res?.data);
  },
  async getCompletedOffersCount() {
    const url = "user_offer/offerwall/completed/count";
    const res = await apiAxiosV1.get(url);
    return OfferCount.from(res?.data);
  },
  async getExpiredOffersCount() {
    const url = "user_offer/offerwall/expired/count";
    const res = await apiAxiosV1.get(url);
    return OfferCount.from(res?.data);
  },

  async addVote({ offerId, vote, comment }) {
    const url = "user_offer/vote/submit";
    const res = await apiAxiosV1.post(url, {
      offer_id: offerId,
      vote_state: vote,
      vote_comment: comment,
    });
    return res?.data;
  },
};
